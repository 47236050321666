import { Controller } from "@hotwired/stimulus"
import { parseMaskedFloat, show, hide } from "../../utils/index"

export default class SumLineController extends Controller {
  static targets = [
    "alertIcon",
    "expenseAmount",
    "expenseLineItemSubTotal",
    "expenseSum",
    "itemLineItemSubTotal",
    "itemLineTotal",
    "itemQuantity",
    "itemRate",
    "itemSum",
    "mainTotal",
    "spendAlert",
    "total",
    "currencyMismatchAlert",
  ]
  alertIconTarget: HTMLElement
  expenseAmountTargets: HTMLInputElement[]
  expenseLineItemSubTotalTarget: HTMLElement
  expenseSumTarget: HTMLElement
  hasExpenseAmountTarget: boolean
  hasExpenseLineItemSubTotalTarget: boolean
  hasExpenseSumTarget: boolean
  hasItemQuantityTarget: boolean
  hasItemLineItemSubTotalTarget: boolean
  hasItemSumTarget: boolean
  itemLineItemSubTotalTarget: HTMLElement
  itemLineTotalTargets: HTMLElement[]
  itemQuantityTargets: HTMLInputElement[]
  itemRateTargets: HTMLInputElement[]
  hasItemRateTarget: boolean
  itemSumTarget: HTMLElement
  mainTotalTarget: HTMLElement
  spendAlertTarget: HTMLElement
  hasSpendAlertTarget: boolean
  totalTargets: HTMLElement[]
  currencyMismatchAlertTarget: HTMLDivElement
  hasCurrencyMismatchAlertTarget: boolean

  static values = {
    requestAnticipatedSpend: Number,
    currentCurrencyCode: String,
    requestCurrencyCode: String,
  }
  requestAnticipatedSpendValue: number
  currentCurrencyCodeValue: string
  requestCurrencyCodeValue: string

  connect(): void {
    this.calculateSums()
  }

  calculateSums(): void {
    this.calculateExpenseSum()
    this.calculateItemSum()
  }

  calculateExpenseSum(): void {
    if (!this.hasExpenseAmountTarget || !this.hasExpenseLineItemSubTotalTarget) return

    const expenseInputs = this.expenseAmountTargets
    const sum = expenseInputs.reduce((acc, input) => acc + (parseMaskedFloat(input.value) || 0), 0)
    this.expenseSumTarget.textContent = this.formatCurrency(+sum.toFixed(2))
    this.expenseLineItemSubTotalTarget.textContent = this.formatCurrency(+sum.toFixed(2))

    this.updateTotal()
  }

  calculateItemSum(): void {
    let sum = 0

    if (!this.hasItemRateTarget) return

    this.itemRateTargets.forEach((item: { value: string }, index: string | number) => {
      const quantity = parseFloat(this.itemQuantityTargets[index]?.value) || 0
      const itemValue = parseMaskedFloat(item.value) || 0

      const totalLineValue = quantity * itemValue
      sum += totalLineValue

      this.itemLineTotalTargets[index].textContent = this.formatCurrency(+totalLineValue.toFixed(2))
    })

    this.itemSumTarget.textContent = this.formatCurrency(sum)
    this.itemLineItemSubTotalTarget.textContent = this.formatCurrency(sum)

    this.updateTotal()
  }

  updateTotal(): void {
    // This is to clear the total if there are no items or expenses
    this.conditionallyClearLineItemTotals

    const expenseSum = this.hasExpenseSumTarget ? parseMaskedFloat(this.expenseSumTarget.textContent || "0") : 0
    const itemSum = this.hasItemSumTarget ? parseMaskedFloat(this.itemSumTarget.textContent || "0") : 0
    const total = +(expenseSum + itemSum).toFixed(2)

    this.totalTargets.forEach((target: HTMLElement) => {
      target.textContent = this.formatCurrency(total)
    })

    this.toggleCurrencyMismatchWarning()
    this.toggleOverspendAlert(total)
  }

  conditionallyClearLineItemTotals(): void {
    if (!this.hasItemQuantityTarget && this.hasItemLineItemSubTotalTarget && this.hasItemSumTarget) {
      this.itemLineItemSubTotalTarget.textContent = "0"
      this.itemSumTarget.textContent = "0"
    }
    if (!this.hasExpenseAmountTarget && this.hasExpenseLineItemSubTotalTarget && this.hasExpenseSumTarget) {
      this.expenseLineItemSubTotalTarget.textContent = "0"
      this.expenseSumTarget.textContent = "0"
    }
  }

  toggleOverspendAlert(total: number): void {
    // View doesn't render the alert if the component doesn't have access to a request
    if (!this.hasSpendAlertTarget) return

    const isOverSpend = this.requestAnticipatedSpendValue < total

    if (isOverSpend && !this.currencyIsMismatched()) {
      show(this.spendAlertTarget)
      show(this.alertIconTarget)
      this.alertIconTarget.classList.remove("hidden")
    } else {
      hide(this.spendAlertTarget)
      hide(this.alertIconTarget)
      this.alertIconTarget.classList.add("hidden")
    }
  }

  toggleCurrencyMismatchWarning(): void {
    // View doesn't render the alert if a user hasn't selected a currency
    // or if the component doesn't have access to a request
    if (!this.hasCurrencyMismatchAlertTarget) return

    if (this.currencyIsMismatched()) {
      show(this.currencyMismatchAlertTarget)
    } else {
      hide(this.currencyMismatchAlertTarget)
    }
  }

  formatCurrency(amount: number): string {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: this.currentCurrencyCodeValue,
      minimumFractionDigits: 2,
    }).format(amount)
  }

  currencyIsMismatched(): boolean {
    return this.currentCurrencyCodeValue !== this.requestCurrencyCodeValue
  }
}
