import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"
import { trigger } from "../../utils/events"

interface SubmitEvent extends CustomEvent {
  detail: { success: boolean }
}

interface FlashPresentEvent extends CustomEvent {
  detail: { flashElement: HTMLElement }
}

// Connects to data-controller="canopy--dialog-component"
export default class extends Controller {
  static targets = ["dialog", "flashPlaceholder"]

  static values = {
    closeOnFormSubmit: Boolean,
    referredFromUrl: Boolean,
    redirectUrl: String,
  }

  closeOnFormSubmitValue: boolean
  referredFromUrlValue: boolean
  redirectUrlValue: string
  dialogTarget: HTMLDialogElement
  flashPlaceholderTarget: HTMLDialogElement
  flashHandler: FlashHandler

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  onDrawerOpenFunction: (event) => void

  connect() {
    if (!this.isPreview) {
      this.open()
    }

    this.flashHandler = new FlashHandler(this.flashPlaceholderTarget)

    document.addEventListener("turbo:submit-end", this.handleSubmit)
    this.element.addEventListener("click", this.closeBackdrop)
    document.addEventListener("keyup", this.handleKeyup)
    document.addEventListener("flash:present", this.handleFlashPresent.bind(this))
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
    this.element.removeEventListener("click", this.closeBackdrop)
    document.removeEventListener("keyup", this.handleKeyup)
    document.removeEventListener("flash:present", this.handleFlashPresent.bind(this))
    delete this.flashHandler
  }

  open() {
    this.dialogTarget.showModal()
    this.dialogTarget.classList.remove(
      this.dialogTarget.classList.contains("drawer") ? "translate-x-full" : "translate-y-full",
    )
    requestAnimationFrame(() => {
      this.dialogTarget.classList.remove("opacity-0")
    })

    // Remove focus from the close button
    document.getElementById("close-dialog-button")?.blur()
  }

  close() {
    // if a dialog is opened from a url and not from an action in app redirect the user to the root url
    if (!this.referredFromUrlValue && this.redirectUrlValue !== "") {
      window.location.href = this.redirectUrlValue
    }

    trigger("drawer:close", {})
    this.dialogTarget.classList.add(
      this.dialogTarget.classList.contains("drawer") ? "translate-x-full" : "translate-y-full",
    )
    requestAnimationFrame(() => {
      this.dialogTarget.close()
      this.element.remove()
      this.dialogTarget.classList.add("opacity-0")
    })
  }

  handleSubmit = (e: CustomEvent) => {
    if (this.closeOnFormSubmitValue && e.detail.success) {
      this.close()
    }
  }

  handleFlashPresent(event: FlashPresentEvent) {
    if (this.dialogTarget.open && this.flashHandler !== undefined) {
      this.flashHandler.handleFlashPresent(event.detail.flashElement)
    }
  }

  // Closes modal when clicking on backdrop
  closeBackdrop = (e: MouseEvent) => {
    const target = e.target as HTMLDialogElement
    if (target.nodeName === "DIALOG") {
      if (target === this.flashPlaceholderTarget) {
        target.close()
      } else {
        this.close()
      }
    }
  }

  // New method to handle keyup events
  handleKeyup = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      this.close()
    }
  }
}

// Flash Handler Functionality
class FlashHandler {
  constructor(private flashPlaceholderTarget: HTMLDialogElement) {}

  handleFlashPresent(flashElement: HTMLElement) {
    this.showPlaceholder(flashElement)
    this.setupCloseHandlers()
  }

  private showPlaceholder(flashElement: HTMLElement) {
    const { flashPlaceholderTarget } = this

    flashPlaceholderTarget.innerHTML = flashElement.innerHTML

    // Ensure we're not overriding the normal flash behaviour
    if (flashPlaceholderTarget.isConnected && !flashPlaceholderTarget.open) {
      // show the dialog flash
      flashPlaceholderTarget.showModal()
      flashElement.classList.add("opacity-0")
      requestAnimationFrame(() => {
        flashPlaceholderTarget.classList.add("flash-visible")
        // Remove focus from the close button
        flashPlaceholderTarget.focus()
      })
    } else {
      // show the normal flash
      flashElement.classList.add("opacity-100")
    }
  }

  private setupCloseHandlers() {
    const { flashPlaceholderTarget } = this
    const closeButton = flashPlaceholderTarget.querySelector('[data-action="flash#close"]')

    closeButton?.addEventListener("click", this.closeFlashMessages)
    window.setTimeout(this.closeFlashMessages, 6000)
  }

  closeFlashMessages = () => {
    const { flashPlaceholderTarget } = this
    const nonDialogFlash = document.getElementById("flash_message_container") as HTMLDivElement

    nonDialogFlash?.remove()

    flashPlaceholderTarget.innerHTML = ""
    flashPlaceholderTarget.close()
  }
}
