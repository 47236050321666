import { Controller } from "@hotwired/stimulus"
import { isEmpty } from "lodash"

// Connects to data-controller="document-switcher"
export default class extends Controller {
  static targets = ["turboFrame"]

  turboFrameTarget: HTMLDivElement

  loadDocument(event) {
    if (event.currentTarget.dataset.documentUrl) {
      const windowUrl = new URLSearchParams(window.location.search)
      const currentDocumentId = windowUrl.get("document_id")

      // if no document is open, or the new document is different from one already open
      if (isEmpty(currentDocumentId) || !event.currentTarget.dataset.documentUrl.includes(currentDocumentId)) {
        const url = event.currentTarget.dataset.documentUrl
        Turbo.visit(url, { frame: this.turboFrameTarget.id })
      }
    }
  }
}
