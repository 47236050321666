import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--price-check-form"
export default class extends Controller {
  static targets = ["messageField", "unitsField", "quotedPriceField", "form"]

  declare messageFieldTarget: HTMLInputElement
  declare unitsFieldTarget: HTMLInputElement
  declare quotedPriceFieldTarget: HTMLInputElement
  declare formTarget: HTMLFormElement

  handleSubmit(event: Event) {
    event.preventDefault()

    const product = document.getElementById("ai-price-check-product-list")?.selectedOptions[0].text
    const units = this.unitsFieldTarget.value
    const quotedPrice = this.quotedPriceFieldTarget.value

    this.messageFieldTarget.value = `Price benchmark for product: ${product}, number of units: ${units}, quoted price: ${quotedPrice}`
    this.formTarget.requestSubmit()
  }
}
