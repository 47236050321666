import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--global-assistant"
export default class extends Controller {
  DEFAULT_MESSAGE_INPUT_HEIGHT = 40
  MAX_MESSAGE_INPUT_HEIGHT = 150

  static targets = ["form", "messageTextarea"]

  formTarget: HTMLFormElement
  messageTextareaTarget: HTMLTextAreaElement

  connect() {
    this.messageTextareaTarget.addEventListener("keypress", this.handleMessageKeyDown)
    this.formTarget.addEventListener("turbo:submit-start", this.onSubmitForm)
    this.formTarget.addEventListener("turbo:submit-end", this.onSubmitFormEnd)
  }

  disconnect() {
    this.formTarget.removeEventListener("turbo:submit-start", this.onSubmitForm)
    this.formTarget.removeEventListener("turbo:submit-end", this.onSubmitFormEnd)
    this.messageTextareaTarget.removeEventListener("keypress", this.handleMessageKeyDown)
  }

  resizeMessageInput = () => {
    if (this.messageTextareaTarget.scrollHeight < this.MAX_MESSAGE_INPUT_HEIGHT) {
      this.messageTextareaTarget.style.height = `${this.messageTextareaTarget.scrollHeight}px`
    }
  }

  handleMessageKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      this.formTarget.requestSubmit()
    }
  }

  onSubmitForm = () => {
    this.dispatch("submit", { detail: { content: this.messageTextareaTarget.value } })

    this.messageTextareaTarget.value = ""
    this.messageTextareaTarget.style.height = `${this.DEFAULT_MESSAGE_INPUT_HEIGHT}px`
  }

  onSubmitFormEnd = () => {
    this.messageTextareaTarget.focus()
  }

  // The agent may return multiple options for the user to choose from.
  // Each option is a button that submits the form with its value as the message.
  submitButtonOption = (event: Event) => {
    const value = (event.currentTarget as HTMLButtonElement).value

    this.messageTextareaTarget.value = value
    this.formTarget.requestSubmit()
  }

  submitButtonName = (event: Event) => {
    const value = (event.currentTarget as HTMLButtonElement).name

    this.messageTextareaTarget.value = value
    this.formTarget.requestSubmit()
  }

  selectSuggestion = (event: Event) => {
    event.preventDefault()

    const target = event.currentTarget as HTMLElement
    const suggestionValue = target.dataset.suggestionValue

    console.log("Suggestion clicked:", {
      value: suggestionValue,
    })

    this.messageTextareaTarget.value = suggestionValue
    this.formTarget.requestSubmit()
  }

  submitSelectOption = (event: Event) => {
    this.messageTextareaTarget.value = event.target.tomselect.getValue()
    this.formTarget.requestSubmit()
  }
}
