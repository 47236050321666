import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  formTarget: HTMLFormElement
  sortByFieldTarget: HTMLInputElement
  sortDirFieldTarget: HTMLInputElement
  searchFieldTarget: HTMLInputElement
  sortByValue: string
  sortDirValue: string
  modelNameValue: string
  organizationIdValue: string
  startDateValue: string
  endDateValue: string

  static targets = ["form"]

  static values = {
    sortBy: String,
    sortDir: String,
    modelName: String,
    organizationId: String,
    startDate: String,
    endDate: String,
  }

  submit() {
    event.preventDefault()
    this.addHiddenFields()
    this.formTarget.submit()
  }

  addHiddenFields() {
    const urlParams = new URLSearchParams(window.location.search)
    const mappedParams = {}

    for (let p of urlParams) {
      const matchingKeys = urlParams.getAll(p[0])

      if (matchingKeys != null) {
        mappedParams[p[0]] = matchingKeys
      }
    }

    this.handleBaseFields(mappedParams)
    this.handleFilterFields(mappedParams)
  }

  handleBaseFields(params) {
    this.addHiddenField(params.sort_by || this.sortByValue, `${this.modelNameValue}[sort_by]`)
    this.addHiddenField(params.sort_dir || this.sortDirValue, `${this.modelNameValue}[sort_dir]`)
    if (params.group_by) {
      this.addHiddenField(params.group_by, `${this.modelNameValue}[group_by]`)
    }
    if (params.q) {
      this.addHiddenField(params.q, `${this.modelNameValue}[q]`)
    }
    if (this.organizationIdValue) {
      this.addHiddenField(this.organizationIdValue, "organization_id")
      this.addHiddenField(this.organizationIdValue, `${this.modelNameValue}[filter_by][organizations.id][]`)
    }
    if (this.startDateValue && this.endDateValue) {
      this.addHiddenField(this.startDateValue, `${this.modelNameValue}[start_date]`)
      this.addHiddenField(this.endDateValue, `${this.modelNameValue}[end_date]`)
    }
  }

  handleFilterFields(params) {
    for (let arr of Object.entries(params)) {
      if (arr[0].match(/organizations.id/) && this.organizationIdValue) {
        continue
      } else if (arr[0].match(/filter_by/)) {
        const base_name = arr[0].replace(/filter_by/, "")
        this.addHiddenFilterField(
          arr[1].filter((k) => k),
          `${this.modelNameValue}[filter_by]${base_name}`,
        )
      }
    }
  }

  addHiddenFilterField(values, name) {
    if (!Array.isArray(values) || values.length == 0) {
      return
    } else if (values.length == 1) {
      this.addHiddenField(values, name)
    } else {
      values.forEach((value) => this.addHiddenField(value, name))
    }
  }

  addHiddenField(values, name) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = name
    input.value = values

    this.formTarget.appendChild(input)
  }
}
