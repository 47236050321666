import React, { createContext, useContext, useState, useEffect } from "react"
import { getVisualBuilderCardDimensions } from "../../../../utils"

const WorkflowContext = createContext({})

const WorkflowContextProvider = ({ ...props }) => {
  const queryStringParams = new URLSearchParams(window.location.search)

  const [selectedTaskId, setSelectedTaskId] = useState(props.selectedTaskId)
  const [hoveredTaskId, setHoveredTaskId] = useState(null)
  const [selectedTaskRequestStage, setSelectedTaskRequestStage] = useState(props.selectedTaskRequestStage)
  const [editable, setEditable] = useState(props.editable)
  const [isSubworkflow, setIsSubworkflow] = useState(props.isSubworkflow)
  const cardDimensions = getVisualBuilderCardDimensions(props.isWorkflow)
  const [taskOpened, setTaskOpened] = useState(queryStringParams.has("selected"))
  const [taskIsUnsaved, setTaskIsUnsaved] = useState(false)
  const [taskHoverDelay, setTaskHoverDelay] = useState(null)

  const redirectToTask = (
    path: string,
    viewportPosition: string,
    targetTaskId: string,
    previousSelectedTaskId: string = null,
    requestStage: string = null,
    sidebarTab: string = null,
  ) => {
    const redirectUrl = new URL(path, window.location.origin)
    const params = redirectUrl.searchParams

    if (viewportPosition) {
      params.set("viewport_position", viewportPosition)
    } else {
      params.delete("viewport_position")
    }

    if (targetTaskId) {
      params.set("selected", targetTaskId)
    } else {
      params.delete("selected")
    }

    if (requestStage) {
      params.set("request_stage", requestStage)
    } else {
      params.delete("request_stage")
    }

    if (sidebarTab) {
      params.set("current_tab", sidebarTab)
    } else {
      params.delete("current_tab")
    }

    if (taskIsUnsaved) {
      document.dispatchEvent(
        new CustomEvent("WorkflowsTaskForm:submit", {
          detail: {
            redirectUrl: redirectUrl.toString(),
            targetTaskId: targetTaskId,
            previousSelectedTaskId: previousSelectedTaskId,
          },
        }),
      )
    } else {
      location.href = redirectUrl.toString()
    }
  }

  return (
    <WorkflowContext.Provider
      value={{
        selectedTaskId,
        setSelectedTaskId,
        selectedTaskRequestStage,
        setSelectedTaskRequestStage,
        editable,
        isSubworkflow,
        setEditable,
        cardDimensions,
        // Rename the references to drawerOpened setDrawerOpened in other files to
        // taskOpened and setTaskOpened when the workflow_simplification_workflow_editing flag is removed
        drawerOpened: taskOpened,
        setDrawerOpened: setTaskOpened,
        hoveredTaskId,
        setHoveredTaskId,
        taskIsUnsaved,
        setTaskIsUnsaved,
        taskHoverDelay,
        setTaskHoverDelay,
        redirectToTask,
      }}
      {...props}
    />
  )
}

export const useWorkflow = () => {
  const context = useContext(WorkflowContext)

  if (!context) {
    throw new Error("The useWorkflow hook must be use inside a WorkflowContextProvider")
  }

  return context
}

export default WorkflowContextProvider
