import { Controller } from "@hotwired/stimulus"
import { debounce, throttle } from "lodash"

// Connects to data-controller="table-component"
export default class extends Controller {
  static targets = ["tableComponent", "tableInner"]
  tableComponentTarget: HTMLDivElement
  tableInnerTarget: HTMLDivElement

  static values = {
    stickyBulkActions: Boolean,
    // Makes columns sticky to the left or right side
    stickyColumns: Boolean,
    // If present, determines how many columns to stick on the left
    stickyColumnIndex: String,
    // If present, determines how many columns to stick on the right
    reverseStickyColumnIndex: String,
    stickyTable: Boolean,
    headerRowBgColors: Array,
  }

  hasTableInnerTarget: boolean
  stickyBulkActionsValue: boolean
  stickyColumnsValue: boolean
  stickyColumnIndexValue: string
  reverseStickyColumnIndexValue: string
  stickyTableValue: boolean
  headerRowBgColorsValue: string[]
  createdCssSelectors: string[] = []
  makeCellsStickyFromEventFunc: (event) => void
  recalculateStickyColumnsInCssFunc: () => void

  createdCssRulesCount: number

  connect() {
    this.createdCssRulesCount = 0

    if (this.stickyColumnsValue) {
      this.makeColumnsSticky()
      this.addStickyColumnsToCss()
    }

    if (this.stickyTableValue) {
      this.makeHeaderSticky()
    }

    if (this.stickyBulkActionsValue && this.hasTableInnerTarget) {
      this.adjustScrollableAreas()
    }

    this.makeCellsStickyFromEventFunc = this.makeCellsStickyFromEvent.bind(this)
    this.recalculateStickyColumnsInCssFunc = debounce(this.recalculateStickyColumnsInCss.bind(this), 100)

    window.addEventListener("bulkEdit:rowRerendered", this.makeCellsStickyFromEventFunc)
    window.addEventListener("bulkEdit:rowRerendered", this.recalculateStickyColumnsInCssFunc)
    this.resizeObserver().observe(this.element)
  }

  disconnect() {
    window.removeEventListener("bulkEdit:rowRerendered", this.makeCellsStickyFromEventFunc)
    window.removeEventListener("bulkEdit:rowRerendered", this.recalculateStickyColumnsInCssFunc)
    this.resizeObserver().unobserve(this.element)
    this.removeStickyColumnsFromCss()
  }

  makeCellsStickyFromEvent(event) {
    this.makeCellsSticky(event.detail.row)
  }

  adjustScrollableAreas() {
    const bulkEditHeader = this.element.querySelector(".bulk-selection-header")

    if (!bulkEditHeader) {
      return
    }

    const clone = bulkEditHeader.cloneNode(true)
    const stickyTableClassOffset = 264

    // Clone element outside of view to get hidden bulk header element height
    clone.setAttribute("style", "display:block;visibility:hidden;position:fixed;left:100%")
    bulkEditHeader.after(clone)
    this.tableInnerTarget.setAttribute(
      "style",
      `max-height: calc(100vh - ${stickyTableClassOffset + clone.offsetHeight}px)`,
    )
    this.tableInnerTarget.classList.add("overflow-auto", "w-full")
    this.element.classList.add("overflow-hidden")
    clone.remove()
  }

  resizeObserver() {
    return new ResizeObserver(
      throttle((element) => {
        this.recalculateStickyColumnsInCssFunc()
      }, 100),
    )
  }

  makeHeaderSticky() {
    const headerRow = this.tableComponentTarget.getElementsByClassName("table-header-group")[0]
    const headerRowCells = headerRow.getElementsByClassName("table-cell")

    Array.prototype.forEach.call(headerRowCells, (cell, index) => {
      if (
        (this.hasStickyColumnIndex() && index <= this.getStickyColumnIndex()) ||
        (this.hasReverseStickyColumnIndex() && index >= this.getReverseStickyColumnIndex())
      ) {
        cell.style.zIndex = 10
      } else {
        cell.style.zIndex = 5
      }

      cell.style.top = "0px"
      cell.style.position = "sticky"
      cell.classList.add(
        ...["bg-inherit", "border-t", "border-b", "shadow-[2px_0px_3px_rgba(0,0,0,0.08)]", "border-base"],
      )
    })
  }

  makeColumnsSticky() {
    const headerRow = this.tableComponentTarget.getElementsByClassName("table-header-group")[0]
    if (headerRow) {
      this.makeCellsSticky(headerRow)
    }

    const tableRows = this.tableComponentTarget.getElementsByClassName("table-row")

    Array.prototype.forEach.call(tableRows, (row) => this.makeCellsSticky(row))
  }

  // by using this ad-hoc css we limit number of reflows that needs to happen
  // from n = rows to just one reflow to add css rules
  addStickyColumnsToCss() {
    let createCssRules = (isReverse: boolean) => {
      let stylesheet = document.styleSheets[0]

      let parentSelector = `[data-controller="${this.element.getAttribute("data-controller")}"]`

      if (this.element.getAttribute("id")) {
        parentSelector = `#${this.element.getAttribute("id")}${parentSelector}`
      }

      let currentOffset = 0
      let columnIndex = isReverse ? this.getReverseStickyColumnIndex() : this.getStickyColumnIndex()

      Array.from(Array(columnIndex + 1).keys()).forEach((index) => {
        let nthSelector = isReverse ? "nth-last-of-type" : "nth-of-type"
        let position = isReverse ? "right" : "left"

        let cellOffset =
          this.tableComponentTarget.querySelector(`.table-cell:${nthSelector}(${index + 1})`).offsetWidth - 1

        let selector = `${parentSelector} .table-cell:${nthSelector}(${index + 1})`

        let columnRule = `
          ${selector} {
          position: sticky;
          ${position}: ${currentOffset}px;
          z-index: 5;
          }
        `

        currentOffset += cellOffset
        this.createdCssSelectors.push(selector)
        this.createdCssRulesCount += 1

        stylesheet.insertRule(columnRule, 0)
      })
    }

    if (this.hasStickyColumnIndex()) {
      createCssRules(false)
    }

    if (this.hasReverseStickyColumnIndex()) {
      createCssRules(true)
    }
  }

  recalculateStickyColumnsInCss() {
    let calculateOffsets = (isReverse: boolean) => {
      let offsets = []
      let currentOffset = 0

      let columnIndex = isReverse ? this.getReverseStickyColumnIndex() : this.getStickyColumnIndex()

      Array.from(Array(columnIndex + 1).keys()).forEach((index) => {
        offsets.push(currentOffset)

        let nthSelector = isReverse ? "nth-last-of-type" : "nth-of-type"

        let cellOffset =
          this.tableComponentTarget.querySelector(`.table-cell:${nthSelector}(${index + 1})`).offsetWidth - 1

        currentOffset += cellOffset
      })

      let cssRules = document.styleSheets[0].cssRules

      Array.from(cssRules).forEach((rule: CSSStyleRule, index: number) => {
        if (!this.createdCssSelectors.includes(rule.selectorText)) {
          return
        }
        let nthChild = parseInt(rule.selectorText.match(/nth.*\((\d+)\)/)[1])

        let position = isReverse ? "right" : "left"

        rule.style[position] = offsets[nthChild - 1] + "px"
      })
    }

    if (this.hasStickyColumnIndex()) {
      calculateOffsets(false)
    }

    if (this.hasReverseStickyColumnIndex()) {
      calculateOffsets(true)
    }
  }

  removeStickyColumnsFromCss() {
    if (this.hasStickyColumnIndex() || this.hasReverseStickyColumnIndex()) {
      let rules: CSSRuleList = document.styleSheets[0].cssRules

      for (let i = this.createdCssRulesCount - 1; i >= 0; i--) {
        if (rules[i].selectorText.includes("table-cell:nth-of-type")) {
          document.styleSheets[0].deleteRule(i)
        }
      }
    }
  }

  hasStickyColumnIndex() {
    return !isNaN(parseInt(this.stickyColumnIndexValue))
  }

  getStickyColumnIndex() {
    return parseInt(this.stickyColumnIndexValue)
  }

  hasReverseStickyColumnIndex() {
    return !isNaN(parseInt(this.reverseStickyColumnIndexValue))
  }

  getReverseStickyColumnIndex() {
    return parseInt(this.reverseStickyColumnIndexValue)
  }

  makeCellsSticky = (row) => {
    const rowCells = row.getElementsByClassName("table-cell")
    let headerRow = false
    this.headerRowBgColorsValue.forEach((bgColor) => {
      if (row.classList.contains(bgColor)) {
        headerRow = true
      }
    })

    if (!this.hasStickyColumnIndex() && !this.hasReverseStickyColumnIndex()) {
      return
    }

    Array.prototype.forEach.call(rowCells, (cell, index) => {
      if (this.hasStickyColumnIndex() && index <= this.getStickyColumnIndex()) {
        if (headerRow) {
          cell.classList.add("bg-inherit")
        } else {
          cell.classList.add(...["bg-white", "group-hover:bg-inherit"])
        }
        if (index == this.getStickyColumnIndex()) {
          cell.classList.add(...["border-r", "shadow-[2px_0px_3px_rgba(0,0,0,0.08)]", "border-base"])
        }
      }

      if (this.hasReverseStickyColumnIndex() && index >= rowCells.length - (this.getReverseStickyColumnIndex() + 1)) {
        if (headerRow) {
          cell.classList.add("bg-inherit")
        } else {
          cell.classList.add(...["bg-white", "group-hover:bg-inherit"])
        }

        if (index == rowCells.length - (this.getReverseStickyColumnIndex() + 1)) {
          cell.classList.add(...["border-l", "shadow-[-2px_0px_3px_rgba(0,0,0,0.08)]", "border-base"])
        }
      }
    })
  }
}
