import { Controller } from "@hotwired/stimulus"
import { enable, disable, hide, show } from "../../../utils"

// Connects to data-controller="ai--policy-compliance-checks--new-check-form-component"
export default class extends Controller {
  static targets = ["checkbox", "submit", "contents"]

  checkboxTargets: HTMLInputElement[]
  submitTarget: HTMLButtonElement
  contentsTarget: HTMLElement

  connect() {
    const resultsElement = document.getElementById("ai_chat_policy_compliance_check_results")
    if (resultsElement && this.element.compareDocumentPosition(resultsElement) & Node.DOCUMENT_POSITION_FOLLOWING) {
      hide(this.contentsTarget)
    } else {
      show(this.contentsTarget)
    }
  }
  toggleCheckbox(_event) {
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState() {
    const anyChecked = this.checkboxTargets.some((checkbox) => checkbox.checked)
    if (anyChecked) {
      enable(this.submitTarget)
    } else {
      disable(this.submitTarget)
    }
  }

  hideContents() {
    hide(this.contentsTarget)
  }
}
