import { Controller } from "@hotwired/stimulus"
import { enable, disable } from "../utils"
import { debounce } from "lodash"

// Connects to data-controller="undo-redo"
export default class extends Controller {
  static targets = ["undoButton", "redoButton"]

  undoButtonTarget: HTMLButtonElement
  redoButtonTarget: HTMLButtonElement

  hasUndoButtonTarget: boolean
  hasRedoButtonTarget: boolean

  undoStack = []
  redoStack = []

  // Semaphore to prevent infinite loop due to tomselect triggering change event on setValue
  propagateEvents = true

  handleAddUndoActionsFunction: (event) => void
  handleKeydownFunction: (event) => void
  disableOrEnableRedoUndoFunction: (status) => void
  disableOrEnableRedoUndoFunctionRendering: void
  disableOrEnableRedoUndoFunctionRendered: void
  debouncedAddUndoAction: (event) => void

  connect(): void {
    this.debouncedAddUndoAction = debounce(this.addUndoAction, 400)
    this.handleAddUndoActionsFunction = this.handleAddUndoActions.bind(this)
    this.handleKeydownFunction = this.handleKeydown.bind(this)
    this.disableOrEnableRedoUndoFunction = this.disableOrEnableRedoUndo.bind(this)
    this.disableOrEnableRedoUndoFunctionRendering = this.disableOrEnableRedoUndo.bind(this, "rendering")
    this.disableOrEnableRedoUndoFunctionRendered = this.disableOrEnableRedoUndo.bind(this, "rendered")

    window.addEventListener("undo-redo:add-undo-actions", this.handleAddUndoActionsFunction)
    window.addEventListener("keydown", this.handleKeydownFunction)
    window.addEventListener("bulkEdit:rowRerendering", this.disableOrEnableRedoUndoFunctionRendering)
    window.addEventListener("bulkEdit:rowRerendered", this.disableOrEnableRedoUndoFunctionRendered)
  }

  disconnect(): void {
    window.removeEventListener("undo-redo:add-undo-actions", this.handleAddUndoActionsFunction)
    window.removeEventListener("keydown", this.handleKeydown)
    window.removeEventListener("bulkEdit:rowRerendering", this.disableOrEnableRedoUndoFunctionRendering)
    window.removeEventListener("bulkEdit:rowRerendered", this.disableOrEnableRedoUndoFunctionRendered)
  }

  disableOrEnableRedoUndo(status) {
    if (status == "rerendering") {
      this.disableOrEnableUndoButton("disable")
      disable(this.redoButtonTarget)
    } else {
      this.refreshButtons()
    }
  }
  undoAction(): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const actions = this.undoStack.pop()

      if (actions) {
        actions.forEach((action) => this.updateInput(action.id, action.previous_value))
        this.redoStack.push(actions)
        this.refreshButtons()
      }

      this.propagateEvents = true
    }
  }

  redoAction(): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      const actions = this.redoStack.pop()

      if (actions) {
        actions.forEach((action) => this.updateInput(action.id, action.current_value))
        this.undoStack.push(actions)
        this.refreshButtons()
      }

      this.propagateEvents = true
    }
  }

  addUndoAction(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      this.addUndoActions([
        {
          id: event.target.id,
          previous_value: event.target.dataset.value || "",
          current_value: event.target.value,
        },
      ])

      this.propagateEvents = true
    }
  }

  clearUndoRedoStacks() {
    this.undoStack = []
    this.redoStack = []
    this.refreshButtons()
  }

  private handleAddUndoActions(event): void {
    if (this.propagateEvents) {
      this.propagateEvents = false

      this.addUndoActions(event.detail.actions)

      this.propagateEvents = true
    }
  }

  private updateInput(inputId, value): void {
    const input = document.getElementById(inputId)

    if (input) {
      const tomselect = input.tomselect

      input.dataset.value = value

      if (tomselect) {
        value = tomselect.settings.mode === "multi" ? value.split(",") : value

        tomselect.setValue(value)
        this.dispatchSelectUpdated(input)
      } else {
        input.value = value
        this.dispatchInputUpdated(input)
      }
    }
  }

  private addUndoActions(actions): void {
    actions.forEach((action) => {
      const input = document.getElementById(action.id)

      if (input) {
        input.dataset.value = action.current_value
      }
    })

    this.undoStack.push(actions)
    this.redoStack = []
    this.refreshButtons()
  }

  private refreshButtons(): void {
    if (this.hasUndoButtonTarget) {
      this.undoStack.length > 0 ? this.disableOrEnableUndoButton("enable") : this.disableOrEnableUndoButton("disable")
    }

    if (this.hasRedoButtonTarget) {
      this.redoStack.length > 0 ? enable(this.redoButtonTarget) : disable(this.redoButtonTarget)
    }
  }

  private disableOrEnableUndoButton(action: String): void {
    if (action == "disable") {
      disable(this.undoButtonTarget)
    } else {
      enable(this.undoButtonTarget)
    }

    window.dispatchEvent(
      new CustomEvent("UndoRedo:undoDisabledOrEnabled", {
        detail: {
          action: action,
        },
      }),
    )
  }

  private handleKeydown(event): void {
    const ctrlKey = event.ctrlKey
    const shiftKey = event.shiftKey
    const metaKey = event.metaKey // command key on Mac
    const keyZ = event.keyCode === 90 // z or Z
    const keyY = event.keyCode === 89 // y or Y

    const ctrlZ = (ctrlKey || metaKey) && !shiftKey && keyZ
    const ctrlY = (ctrlKey || metaKey) && !shiftKey && keyY
    const ctrlShiftZ = (ctrlKey || metaKey) && shiftKey && keyZ

    if (ctrlZ) {
      event.preventDefault()
      this.undoAction()
    } else if (ctrlY || ctrlShiftZ) {
      event.preventDefault()
      this.redoAction()
    }
  }

  private dispatchSelectUpdated(select) {
    window.dispatchEvent(
      new CustomEvent("UndoRedo:selectUpdated", {
        detail: {
          origin: select,
        },
      }),
    )
  }

  private dispatchInputUpdated(input) {
    window.dispatchEvent(
      new CustomEvent("UndoRedo:inputUpdated", {
        detail: {
          origin: input,
        },
      }),
    )
  }
}
