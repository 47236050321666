import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="persist-scroll-position"
export default class extends Controller {
  static targets = ["container"]

  static values = {
    key: String,
  }

  containerTarget: HTMLElement
  keyValue: string

  connect() {
    document.addEventListener("turbo:before-visit", this.saveScrollPosition)

    const savedPosition = sessionStorage.getItem(this.keyValue)
    if (savedPosition) {
      this.containerTarget.scrollTop = parseInt(savedPosition)
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-visit", this.saveScrollPosition)
  }

  saveScrollPosition = () => {
    sessionStorage.setItem(this.keyValue, this.containerTarget.scrollTop.toString())
  }
}
