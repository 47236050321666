import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="ai--global-assistant-sidecar"
export default class extends Controller {
  SIDECAR_STATE_STORAGE = "sidecarState"
  OPEN = "open"
  CLOSED = "closed"

  static targets = ["sidecar", "expandButton", "collapseButton"]

  sidecarTarget: HTMLDivElement
  expandButtonTarget: HTMLButtonElement
  collapseButtonTarget: HTMLButtonElement

  connect() {
    if (this.getState() === this.OPEN) {
      this.showSidecar()
    } else {
      this.hideSidecar()
    }
  }

  hideSidecar() {
    hide(this.sidecarTarget)
    show(this.expandButtonTarget)
    hide(this.collapseButtonTarget)

    this.setState(this.CLOSED)
  }

  showSidecar() {
    show(this.sidecarTarget)
    hide(this.expandButtonTarget)
    show(this.collapseButtonTarget)

    this.setState(this.OPEN)
  }

  getState = () => {
    return localStorage.getItem(this.SIDECAR_STATE_STORAGE) || this.OPEN
  }

  setState = (state: string) => {
    localStorage.setItem(this.SIDECAR_STATE_STORAGE, state)
  }
}
